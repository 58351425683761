.image-wrapper {
    text-align: center;

    img {
        box-shadow: 0px 0px 5px #808080;
        border-radius: 3px;
	max-width: 70%;
        height: auto;
    }

    .image-caption {
        color: #828282;
        margin-top: $spacing-unit / 3;
    }
}
